<template>
  <div id="recycle-bin-wrapper">
    <PageTitle :title="title" />
    <RecycleBinList />
  </div>
</template>

<script setup>
import { defineEmits, onMounted } from "vue";
import RecycleBinList from "./components/RecycleBinList.vue";
import PageTitle from "@/components/base/PageTitle";
const title = "回收站";
// 面包屑
const emits = defineEmits(["setBreadcrumbList"]);
const setBreadcrumbList = () => {
  let BreadcrumbList = [{ title: "回收站" }];
  emits("setBreadcrumbList", BreadcrumbList);
};
setBreadcrumbList();
</script>

<style lang="scss" scoped></style>
