<!-- 
  回收站列表组件
 -->
<template>
  <div id="recycle-bin-list-wrapper" class="list-table">
    <div class="option-bar">
      <div class="search-bar">
        <el-input
          v-model="recycleList.keyword"
          placeholder="输入文本库名称进行检索"
          clearable
          style="width: 200px"
          @keyup.enter="getRecycleList"
        ></el-input>
        <el-button class="search-btn" type="primary" @click="getRecycleList">
          搜索
        </el-button>
      </div>
      <div class="option-btn-box">
        <el-button plain @click="handleDelete()">彻底删除</el-button>
        <el-button type="primary" @click="handleRecover()">恢复</el-button>
      </div>
    </div>
    <div>
      <el-table
        ref="recycleTableRef"
        :data="recycleList.data"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        header-cell-class-name="list-table-header"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column fixed prop="name" label="名称" />
        <el-table-column prop="recordNum" label="记录数" />
        <el-table-column prop="userName" label="创建人" />
        <el-table-column prop="moveTrashTime" label="删除时间" width="160">
          <template #default="scope">
            {{ timeFormatter(scope.row.moveTrashTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="deleteTime" label="清空时间" width="160">
          <template #default="scope">
            {{ timeFormatter(scope.row.deleteTime) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template #default="scope">
            <div class="list-item-btn">
              <el-button
                link
                type="primary"
                @click="handleRecover(scope.row.id)"
              >
                恢复
              </el-button>
              <el-button
                link
                type="primary"
                @click="handleDelete(scope.row.id)"
              >
                彻底删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-box">
      <el-pagination
        v-model:current-page="recycleList.pageNum"
        v-model:page-size="recycleList.pageSize"
        small
        background
        layout="total, prev, pager, next, sizes"
        :total="recycleList.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="recycleList.pageSizeList"
      />
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from "vue";
import { ElMessage, ElTable, ElMessageBox } from "element-plus";
import { httpPost } from "@/api/httpService";
import { timeFormatter } from "@/utils/time.js";

let recycleList = reactive({
  data: [],
  loading: false,
  keyword: "",
  pageNum: 1,
  pageSize: 10,
  pageSizeList: [10, 20, 30, 40],
  total: 20,
  libraryIdList: []
});
const recycleTableRef = ref();
// 获取回收站列表
const getRecycleList = async () => {
  recycleList.loading = true;
  let params = {
    keyword: recycleList.keyword,
    pageNum: recycleList.pageNum,
    pageSize: recycleList.pageSize,
    userId: recycleList.userId
  };
  let res = await httpPost(`/trash/lib/list/page`, params);
  if (res.code == 0) {
    recycleList.data = res.data.data;
    recycleList.total = res.data.total;
  }
  recycleList.loading = false;
};
// 恢复功能
const handleRecover = async (id) => {
  if (!id && recycleList.libraryIdList.length == 0) {
    ElMessage.warning("请选择文本库");
    return;
  }
  let params;
  if (id) {
    params = {
      libraryIdList: [id]
    };
  } else {
    params = {
      libraryIdList: recycleList.libraryIdList
    };
  }
  let res = await httpPost(`/trash/lib/cancel`, params);
  if (res.code === 0) {
    ElMessage.success("恢复成功！");
    getRecycleList();
  }
};

// 删除功能
const handleDelete = (id) => {
  if (!id && recycleList.libraryIdList.length == 0) {
    ElMessage.warning("请选择文本库");
    return;
  }
  ElMessageBox.confirm("确定彻底删除文本库吗？此次删除后将无法恢复", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning"
  })
    .then(async () => {
      let params;
      if (id) {
        params = {
          libraryIdList: [id]
        };
      } else {
        params = {
          libraryIdList: recycleList.libraryIdList
        };
      }
      let res = await httpPost(`/trash/lib/delete/now`, params);
      if (res.code == 0) {
        ElMessage.success("删除成功！");
        getRecycleList();
      }
    })
    .catch(() => {});
};
// 多选操作
const handleSelectionChange = (val) => {
  recycleList.libraryIdList = [];
  val.forEach((v) => {
    recycleList.libraryIdList.push(v.id);
  });
};
const handleSizeChange = (val) => {
  getRecycleList.pageSize = val;
  getRecycleList();
};

const handleCurrentChange = (val) => {
  getRecycleList.pageNum = val;
  getRecycleList();
};
onMounted(() => {
  getRecycleList();
});
</script>
<style lang="scss" scoped>
@import "@/assets/styles/components/list-table";
#recycle-bin-list-wrapper {
  .option-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .search-bar {
      .search-btn {
        margin-left: 28px;
      }
    }
  }
  .pagination-box {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }
}
</style>
